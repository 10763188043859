const sortASC = (array = []) => {
  array?.map(el =>
    [...el?.items].sort((a, b) => {
      const nameA = a.order;
      const nameB = b.order;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }),
  );
};

export default sortASC;
