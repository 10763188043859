import React from 'react';
import { Flex } from '@chakra-ui/react';
import ServiceElementItem from '../ServiceElementItem';

const ServiceElements = ({ services, as, ...other }) => (
  <Flex
    data-testid='services-block'
    justifyContent='space-between'
    wrap='wrap'
    {...other}
  >
    {services.map(item => (
      <ServiceElementItem key={item.id} item={item} as={as} />
    ))}
  </Flex>
);

export default ServiceElements;
