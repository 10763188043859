import dayjs from 'dayjs';
import { declLngVariant } from './helpers';
import { dateFormat } from '../common/constants';

export const getHowLongAgo = dayOne => {
  const sec = Math.round((new Date() - new Date(Number(dayOne))) / 1000);
  if (sec < 60) {
    return {
      context: `sec${declLngVariant(sec || 0)}`,
      count: sec || 0,
    };
  }

  const min = Math.round(sec / 60);
  if (min < 60) {
    return {
      context: `min${declLngVariant(min || 0)}`,
      count: min || 0,
    };
  }

  const hour = Math.round(min / 60);

  if (hour < 24) {
    return {
      context: `hour${declLngVariant(hour || 0)}`,
      count: hour || 0,
    };
  }

  if (hour >= 24 && hour < 48) return { context: 'yesterday' };

  return {
    context: 'date',
    count: dayjs(parseInt(dayOne, 10)).format(dateFormat.DMY),
  };
};

export const isOnline = last =>
  (new Date() - new Date(Number(last))) / (60 * 1000) < 5;
