import React from 'react';
import { Heading, Text, Flex, Button, Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import { SIGNUP_URL } from 'src/common/constants';
import DefaultContainer from '../../layouts/DefaultContainer';

const BecomeExecutorBlock = ({ as }) => {
  const router = useRouter();
  const { t } = useTranslation(['common']);
  return (
    <DefaultContainer data-testid='become-executor-container'>
      <Box
        borderRadius='xl'
        bgImage={[
          'url(/images/earn-xs.jpg)',
          'url(/images/earn-s.jpg)',
          'url(/images/earn-m.jpg)',
        ]}
        bgSize='cover'
        bgRepeat='no-repeat'
        bgPos='right'
        h={[75, 75, 100]}
        w='100%'
      >
        <Flex
          w='100%'
          justifyContent={['center', 'start', 'start']}
          borderRadius='xl'
          h='100%'
          bgGradient={[
            'linear(to-l, #00000055, #00000077, #00000099)',
            'linear(to-l, #00000000, #00000055, #00000077, #00000099)',
            'linear(to-l, #00000000, #00000044, #00000077)',
          ]}
        >
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems={['center', 'start', 'start']}
            maxW={[60, 60, 100]}
            mx={[5, 5, 10]}
          >
            <Heading as={as || 'h4'} size='2-4xl' color='white'>
              {t('become-executor-block.title')}
            </Heading>
            <Text
              as='div'
              fontSize={['md', 'md', 'lg']}
              color='white'
              textAlign={['center', 'start', 'start']}
            >
              {t('become-executor-block.description')}
            </Text>
            <Button
              data-testid='become-executor-block'
              variant='primaryBlack'
              mt={[4, 4, 10]}
              px={[4, 4, 'auto']}
              w={70}
              onClick={() =>
                routerPush(router, SIGNUP_URL)
              }
            >
              {t('button.become-a-executor')}
            </Button>
            <Button
              data-testid='how-to-make-cash'
              variant='primaryBlack'
              mt={4}
              px={[4, 4, 'auto']}
              w={70}
              onClick={() =>
                routerPush(router, '/kak-zarabatyvat-s-masteryoda')
              }
            >
              {t('button.how-to-make-money')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </DefaultContainer>
  );
};

BecomeExecutorBlock.propTypes = {
  me: PropTypes.object,
};

export default BecomeExecutorBlock;
