import React from 'react';
import { Flex, Heading, Img } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import NextLink from '../NextLink';

const AllServicesBtn = () => {
  const { t } = useTranslation('common');
  return (
    <NextLink href={'/near-me'}>
      <Flex
        flexDirection='row-reverse'
        justifyContent={['flex-end', 'flex-end', 'center']}
        alignItems='center'
        w={['100%', '100%', 80]}
        mt={4}
        px={5}
        py={2}
        data-testid='all-services'
        border='1px solid'
        borderColor='brand.primary'
        borderRadius='lg'
        _hover={{
          boxShadow: '0px 7px 30px -11px #429bc2',
          cursor: 'pointer',
        }}
      >
        <Heading as='span' size='fix2xl' color='brand.primary'>
          {t('button.all-services')}
        </Heading>
        <Img _hover={{ w: 7 }} mr={7} src='/icons/service/arrowRight.svg' />
      </Flex>
    </NextLink>
  );
};

export default AllServicesBtn;
