import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { commonStore } from '../../../store/storeProvider';

const PreviewServiceFeedbackDesktopLazy = dynamic(() =>
  import('./PreviewServiceFeedbackDesktop.jsx'),
);
const PreviewServiceFeedbackMobileLazy = dynamic(() =>
  import('./PreviewServiceFeedbackMobile.jsx'),
);

const PreviewServiceFeedback = props => {
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);

  return isMobile ? (
    <PreviewServiceFeedbackMobileLazy {...props} />
  ) : (
    <PreviewServiceFeedbackDesktopLazy {...props} />
  );
};

export default PreviewServiceFeedback;
