import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getPath } from 'src/utils/getPath';
import { JOB_TYPES, PREVIEW_FEEDBACKS } from 'src/gql/query';
import Main from 'src/pages/Main';
import { timeInSeconds, userTypes } from 'src/common/constants';
import {
  checkRequestErrors,
  getServerCLient,
} from 'src/utils/urqlClient/helpers';
import { prepareSsgProps } from 'src/utils/prepareSsgProps';

export async function getStaticProps(context) {
  const { client, ssrCache } = getServerCLient();

  await client
    .query(JOB_TYPES, {
      filter: {
        pagination: {
          page: 0,
          pageSize: 9,
        },
        sortBy: {
          id: 'order',
          value: 'ASC',
        },
      },
    })
    .toPromise();

  await client
    .query(PREVIEW_FEEDBACKS, {
      input: {
        filter: {
          criteria: [
            {
              id: 'type',
              values: userTypes.EXECUTOR,
            },
          ],
          pagination: {
            page: 1,
            pageSize: 15,
          },
        },
      },
    })
    .toPromise();

  const { revalidate, urqlState } = checkRequestErrors(
    ssrCache.extractData(),
    timeInSeconds.DAY,
  );
  const pageNs = ['main', 'common'];

  return {
    props: {
      ...(await serverSideTranslations(context.locale, pageNs)),
      pageNs,
      urqlState,
      ssgContext: prepareSsgProps(context?.params.ssgParams),
    },
    revalidate,
  };
}

export async function getStaticPaths() {
  return {
    paths: getPath(),
    fallback: 'blocking',
  };
}

export default Main;
