import React from 'react';
import { Box, Flex } from "@chakra-ui/react"
import DefaultContainer from "src/layouts/DefaultContainer/"
import CreateTaskPreviewMasterNaChas from "../createTask/CreateTaskPreviewMasterNaChas"

const HeadImageBlock = ({ children }) => (
    <DefaultContainer
        container={{
            title: 'MasterYoda',
            backgroundSize: ['cover', 'cover', 'cover'],
            bgRepeat: ['no-repeat', 'no-repeat', 'no-repeat'],
            bgPosition: ['center center', 'center center', 'center center'],
            bg: [
                'url(/images/cover-xs.jpg)',
                'url(/images/cover-s.jpg)',
                'url(/images/cover-m.jpg)',
            ],
        }}
    >
        <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={['column', 'column', 'row']}
            w='100%'
        >
            {children}
            <Box w={100} px={[4, 4, 0]} pt={[12, 12, 10]} pb={8}>
                <CreateTaskPreviewMasterNaChas
                    onClick={() =>
                        window?.dataLayer?.push({ event: 'quiz-orderer-create' })
                    }
                />
            </Box>
        </Flex>
    </DefaultContainer>
)

export default HeadImageBlock