import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useQuery } from 'urql';
import { userTypes } from 'src/common/constants';
import { PUBLIC_PROFILES } from '../../gql/query';
import { getHowLongAgo } from '../../utils/getLastVisit';
import { commonStore } from '../../store/storeProvider';
import useObserve from '../../hooks/useInView';

const RecentRegistrationsDesktopLazy = dynamic(() =>
  import('./RecentRegistrationsDesktop.jsx'),
);
const RecentRegistrationsMobileLazy = dynamic(() =>
  import('./RecentRegistrationsMobile.jsx'),
);

const RecentRegistrations = props => {
  const { t } = useTranslation(['common']);
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useObserve();
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);
  const pageSize = 36;

  const [{ data }] = useQuery({
    query: PUBLIC_PROFILES,
    variables: {
      filter: {
        pagination: {
          page: 1,
          pageSize,
        },
        criteria: [
          { id: 'status', values: [userTypes.EXECUTOR] },
          { id: 'withImagesOnly', values: ['true'] },
        ],
        sortBy: {
          id: 'createdAt',
          value: 'DESC',
        },
      },
    },
    pause: !isVisible,
  });

  const profiles = data?.publicProfiles?.profiles;
  const profilesList = profiles?.map(el => ({
    ...el,
    createdAt: t('how-long-ago.how-long-ago', {
      ns: 'common',
      ...getHowLongAgo(el?.createdAt),
    }),
  }));

  useEffect(() => {
    if (inView && !isVisible) setIsVisible(true);
  }, [inView]);

  return (
    <>
      <Box ref={ref} />
      {isMobile ? (
        <RecentRegistrationsMobileLazy profilesList={profilesList} {...props} />
      ) : (
        <RecentRegistrationsDesktopLazy
          profilesList={profilesList}
          {...props}
        />
      )}
    </>
  );
};

export default RecentRegistrations;
