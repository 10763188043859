/* eslint-disable no-nested-ternary */
import React from 'react';
import { Heading, Button, Text, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import CreateTaskDescription from '../CreateTaskDescription';
import { useTaskStore } from '../../../store/taskProvider';
import { emptySpaceRegExp } from '../../../utils/regExp';

const styleBtn = {
  variant: 'primaryBlack',
  w: '100%',
  mb: 3,
  'data-gtm': 'create-preview-order',
};

function CreateTaskPreviewMasterNaChas({ onClick, placeholderTitle, jobType }) {
  const { t } = useTranslation(['common']);
  const { setTask } = useTaskStore();
  const router = useRouter();
  const { utpTitle } = router.query;
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const orderSubmit = async taskData => {
    if (onClick) onClick();
    setTask(prev => ({
      ...prev,
      jobType: jobType || null,
      title: taskData?.title?.replace(emptySpaceRegExp, ' ').trim(),
      description: taskData?.description?.replace(emptySpaceRegExp, ' ').trim(),
    }));
    return routerPush(router, '/create-task');
  };

  return (
    <Flex
      bgColor='lightGray.50'
      borderRadius='3xl'
      boxShadow='lg'
      flexDir='column'
      alignItems='center'
      p={6}
      pb={3}
      w='100%'
    >
      <Heading
        as='div'
        textAlign='center'
        fontSize={'3xl'}
        maxWidth={300}
        mb={[4, 4, 5]}
      >
        {utpTitle || t('create-task-preview-master-na-chas.title')}
      </Heading>
      <CreateTaskDescription
        dataTestId='task-description'
        register={register}
        errors={errors}
        placeholder={`${t('create-task-preview-master-na-chas.placeholder')} ${
          placeholderTitle ||
          t('create-task-preview-master-na-chas.placeholderTitle')
        }`}
      />
      <Button
        data-testid='create-task-button'
        {...styleBtn}
        onClick={handleSubmit(orderSubmit)}
      >
        {utpTitle || t('button.create-task', { ns: 'common' })}
      </Button>
      {!utpTitle && (
        <Text as='div' display='inline' w='100%' mb={3}>
          {t('create-task-preview-master-na-chas.description')}
        </Text>
      )}
    </Flex>
  );
}

export default CreateTaskPreviewMasterNaChas;
