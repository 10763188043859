import React, { useContext, useEffect } from 'react';
import { Heading, Wrap, WrapItem } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import settings from 'src/common/settings';
import { setCity } from 'src/store/common/actions';
import { getCityData } from 'src/widgets/SelectCity';
import DefaultContainer from '../../../layouts/DefaultContainer';
import { commonStore } from '../../../store/storeProvider';
import NextLink from '../../../components/NextLink';

const styleHeading = {
  as: 'h3',
  size: '2-4xl',
  mb: [7, 7, 9],
};

const styleContainer = {
  flexDirection: 'column',
  container: { as: 'section' },
};

const styleButton = isMobile => ({
  bg: 'brand.secondaryBackground',
  size: isMobile ? 'sm' : 'md',
  borderRadius: '3xl',
  py: [1, 1, 2],
  px: [4, 4, 6],
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxW: [66, 110, 'auto'],
});

const NeighboringCities = ({ heading, getUrl, city, MyButton }) => {
  const { t } = useTranslation(['common']);
  const {
    dispatch,
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);

  const router = useRouter();
  const { popularCities } = settings;
  useEffect(() => {
    const cityId = `${router?.query?.cityName}--${router?.query?.region}`;

    if (!!router?.query?.cityName && cityId !== city?.id) {
      setCity(dispatch, getCityData(cityId));
    }
  }, [router?.query?.cityName, city?.id]);

  const locations = popularCities
    .filter(el => el.id !== city?.id)
    ?.map(e => {
      return {
        ...e,
        url: getUrl(e?.id),
      };
    });

  return (
    <DefaultContainer {...styleContainer}>
      <Heading {...styleHeading}>{heading}</Heading>
      <Wrap spacing='15px'>
        {locations?.map((item, i) => (
          <WrapItem key={item.id}>
            <NextLink href={item?.url} key={i} {...styleButton(isMobile)}>
              {`${t('preview-service-neighboring-cities.city')} ${item.name}`}
            </NextLink>
          </WrapItem>
        ))}
      </Wrap>
      {MyButton && <MyButton />}
    </DefaultContainer>
  );
};

export default NeighboringCities;
