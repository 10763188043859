import React, { useState } from 'react';
import { Flex, Heading, Button, Tag } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { getHrefWithoutSlash, getPathForCategory } from 'src/utils/urlControl';
import { useRouter } from 'next/router';
import NextLink from '../NextLink';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

const stylesContainer = {
  w: ['100%', 75],
  mb: [10, 10, 15],
  mx: [2, 2, 6],
  flexDirection: 'column',
  'data-testid': 'service-container',
};

const stylesLink = {
  variant: 'primaryLink',
  mb: 2,
  'data-testid': 'service-element-link',
};

const stylesMoreButton = {
  mt: 2,
  variant: 'primaryLink',
  fontWeight: 'bold',
  'data-testid': 'toggle-more-services-button',
};

const ServiceElementItem = ({ item, as }) => {
  const { t } = useTranslation(['common']);
  const numberItems = 5;
  const [isShowAllItems, setShowAllItems] = useState(false);
  const [itemList, setItemList] = useState(item?.items.slice(0, numberItems));
  const router = useRouter();

  const buttonHandler = () => {
    if (isShowAllItems) {
      setItemList(item?.items.slice(0, numberItems));
      setShowAllItems(!isShowAllItems);
    } else {
      setItemList(item?.items);
      setShowAllItems(!isShowAllItems);
    }
  };

  return (
    <Flex key={item.id} {...stylesContainer}>
      <NextLink href={getPathForCategory(`${item.id}`, router)} size='fixLg'>
        <Heading as={as || 'h3'} variant='primaryLink' size='fixLg'>
          {capitalizeFirstLetter(item.name)}
        </Heading>
      </NextLink>
      <Tag as='span' color='brand.primary' mt={0.5} w='max-content'>
        {item.executors || 0}
      </Tag>
      <Flex flexDir='column' ml={2} mt={3}>
        {itemList?.map(el => (
          <NextLink
            {...stylesLink}
            key={el.id}
            href={getPathForCategory(
              getHrefWithoutSlash(`${el.path}`),
              router,
            )}
          >
            {capitalizeFirstLetter(el.name)}
          </NextLink>
        ))}
        {item?.items.length > numberItems && item?.items.length > 1 && (
          <Button {...stylesMoreButton} onClick={() => buttonHandler()}>
            {isShowAllItems
              ? t('button.hide', { count: item?.items.length - numberItems })
              : t('button.add', { count: item?.items.length - numberItems })}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ServiceElementItem;
