import React, { useContext } from 'react';
import { Heading, Box, Button } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useQuery } from 'urql';
import { userTypes } from 'src/common/constants';
import NeighboringCities from 'src/widgets/previewService/NeighboringCities';
import NextLink from 'src/components/NextLink';
import HeadImageBlock from 'src/widgets/HeadImageBlock/HeadImageBlock.jsx';
import { JOB_TYPES, ME, PREVIEW_FEEDBACKS } from '../../gql/query';
import MainPageLayout from '../../layouts/MainPageLayout';
import { withUrqlClient } from '../../utils/urqlClient';
import Services from '../../components/Services';
import BotInfo from '../../components/BotInfo';
import MetaDecorator from '../../utils/seo/MetaDecorator';
import meta from '../../utils/seo/meta';
import BecomeExecutorBlock from '../../components/BecomeExecutorBlock';
import { commonStore } from '../../store/storeProvider';
import { onOpenLogin } from '../../utils/modalControl';
import sortASC from '../../utils/sortASC';
import RecentRegistrations from '../../widgets/RecentRegistrations';
import HowWorkBecomeCustomer from '../../widgets/HowWorkBecomeCustomer';
import PreviewServiceFeedback from '../../widgets/previewService/PreviewServiceFeedback';

function Main({ pageNs }) {
  const { t } = useTranslation(pageNs);
  const router = useRouter();
  const {
    common: {
      ui: { isMobile },
      city,
    },
  } = useContext(commonStore);

  const [me] = useQuery({
    query: ME,
    requestPolicy: 'cache-only',
  });

  const profileId = me?.data?.me?.id;

  const [{ data }] = useQuery({
    query: JOB_TYPES,
    variables: {
      filter: {
        pagination: {
          page: 0,
          pageSize: 9,
        },
        sortBy: {
          id: 'order',
          value: 'ASC',
        },
      },
    },
  });

  const services = data?.jobTypes?.jobTypes;

  sortASC(services);

  const [feedback] = useQuery({
    query: PREVIEW_FEEDBACKS,
    variables: {
      input: {
        filter: {
          criteria: [
            {
              id: 'type',
              values: userTypes.EXECUTOR,
            },
          ],
          pagination: {
            page: 1,
            pageSize: 15,
          },
        },
      },
    },
  });
  const feedbackResults = feedback?.data?.previewFeedbacks?.results?.feedbacks;
  const avgFeedbacksRating =
    feedback?.data?.previewFeedbacks?.results?.averageRating;
  const feedbackTotalCount =
    feedback?.data?.previewFeedbacks?.filter?.totalCount;

  if (!services) return null;

  const onLogin = () => onOpenLogin(router);

  return (
    <>
      <MetaDecorator
        ns={pageNs}
        title={meta.main.title(city?.name)}
        description={meta.main.description(city?.name)}
      />
      <HeadImageBlock>
        <Heading
          textAlign={['center', 'center', 'left']}
          variant='main'
          as='h1'
          maxW={[80, 87.5, 100]}
          lineHeight={1.3}
          mt={[0, 0, 2]}
        >
          {t('title')}
        </Heading>
      </HeadImageBlock>
      <Services as='div' services={services} />
      <HowWorkBecomeCustomer as='h2' isMoreDetails />
      <Box mt={[5]} mb={[5, 5, 10]}>
        <RecentRegistrations as='h2' />
      </Box>

      <BecomeExecutorBlock as='h2' />
      <BotInfo as='h2' onLogin={onLogin} profileId={profileId} />

      <NeighboringCities
        heading={t('services-in-cities', { ns: 'common' })}
        city={city}
        getUrl={id => {
          const [cityKey, regionKey] = id?.split('--');
          return `/locations/${regionKey}/${cityKey}`;
        }}
        MyButton={() => (
          <NextLink href='/locations' mt={4}>
            <Button variant='link' data-testid='all-cities'>
              {t('button.all-cities', { ns: 'common' })}
            </Button>
          </NextLink>
        )}
      />

      <PreviewServiceFeedback
        averageRating={avgFeedbacksRating}
        totalCount={feedbackTotalCount}
        isMobile={isMobile}
        router={router}
        data={feedbackResults}
      />
    </>
  );
}

Main.propTypes = {
  defaultServices: PropTypes.array,
};

const WrappedComponent = withUrqlClient()(Main);
WrappedComponent.Layout = MainPageLayout;
export default WrappedComponent;
