import React from 'react';
import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import DefaultContainer from '../../layouts/DefaultContainer';
import LocationHeading from '../LocationHeading';
import ServiceElements from '../ServiceElements';
import AllServicesBtn from '../AllServicesBtn';

const Services = ({ services, as }) => {
  const { t } = useTranslation('main');
  return (
    <DefaultContainer container={{ as: 'section' }}>
      <Flex alignItems='center' flexDirection='column'>
        <LocationHeading title={t('service-catalog')} as='h2' size='1-4xl' />
        <ServiceElements as={as} services={services} mt={[8, 8, 12]} />
        <AllServicesBtn />
      </Flex>
    </DefaultContainer>
  );
};

Services.propTypes = {
  services: PropTypes.array,
};

export default Services;
