import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/react';

const LocationHeading = ({
  title = '',
  as,
  size,
  isOnlyText = false,
  ...others
}) => {
  return (
    <Heading
      as={as}
      size={size}
      display={!isOnlyText ? 'flex' : 'inline-block'}
      h='max-content'
      {...others}
    >
      {title}
    </Heading>
  );
};

LocationHeading.propTypes = {
  title: PropTypes.string,
  as: PropTypes.string,
  size: PropTypes.string,
  isOnlyCity: PropTypes.bool,
};

export default LocationHeading;
