import React, { useState } from 'react';
import { Flex, Heading, Button, Img, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import useModalPaddings from 'src/hooks/useModalPaddings';
import { fbMessengerBotName, telegramBotName, viberBotName } from 'src/config';
import Modal from '../Modal';
import BotSubscription from '../BotSubscription';
import DefaultContainer from '../../layouts/DefaultContainer';

const BotInfo = ({ profileId, onLogin, as }) => {
  const [isOpen, setOpen] = useState();
  const { t } = useTranslation(['common']);
  const boxPaddings = useModalPaddings();

  return (
    <DefaultContainer data-testid='bot-info' pb={[0, 0, 10]} isPaddingMd>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <BotSubscription position='center' {...boxPaddings} />
      </Modal>
      <Flex
        flexDir={['column-reverse', 'column-reverse', 'row']}
        alignItems='center'
        justifyContent='space-around'
      >
        <Img
          src='/images/bot.jpg'
          boxSize={66}
          mt={[10, 10, 0]}
          mr={[0, 0, 10, 0]}
        />
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems={['center', 'center', 'start']}
        >
          <Heading
            as={as || 'h4'}
            size='2-4xl'
            textAlign={['center', 'start', 'start']}
            maxW={['auto', 'auto', 120]}
          >
            {t('bot-info.title')}
          </Heading>
          <Text
            mt={3}
            as='div'
            fontSize={['md', 'md', 'lg']}
            textAlign={['center', 'start', 'start']}
            maxW={['auto', 'auto', 100]}
          >
            {t('bot-info.description', {
              botNames: [
                fbMessengerBotName && 'Messenger',
                telegramBotName && 'Telegram',
                viberBotName && 'Viber',
              ]
                .filter(e => !!e)
                .join(', '),
            })}
          </Text>
          <Button
            variant='primaryBlack'
            data-testid='bot-subscription-block'
            mt={[9, 9, 10]}
            px={[4, 4, 'auto']}
            w={56}
            onClick={() => (!profileId ? onLogin() : setOpen(true))}
          >
            {t('button.subscribe')}
          </Button>
        </Flex>
      </Flex>
    </DefaultContainer>
  );
};

export default BotInfo;
